import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


// Inline styling

const style = {
  backgroundColor: "#ffffff",
  paddingTop: "6rem",
  paddingBottom: "6rem"
}

// end of styling

const Deposit = ({ location }) => {
  return (
    <>
      <Layout location={location}>
        <SEO title="Deposit" description="" />
        <div className="page-content" style={style}>
          <div className="container">
            <ul className="list-unstyled d-none d-md-flex justify-content-center deposit-step">
              <li>
                <h4 className="hvr-ripple-out">1</h4>
                <h5 className="mt-3">Lead Deposit</h5>
              </li>
              <li>
                <h4>2</h4>
                <h5 className="mt-3 text-muted">Find Contract</h5>
              </li>
              <li>
                <h4>3</h4>
                <h5 className="mt-3 text-muted">Onboarding Forms</h5>
              </li>
              <li>
                <h4>4</h4>
                <h5 className="mt-3 text-muted">Schedule Onboarding</h5>
              </li>
            </ul>
          </div>
          <div className="container-fluid px-0">
            
            <iframe title="Deposit" src="https://forms.ontraport.com/v2.4/include/formEditor/genlightbootstrap.php?uid=p2c190680f39&formType=embed&formGUID=OPF_58729897-36d8-c374-9e8b-23757654ce02&referer=https%3A%2F%2Fwww.leadroll.co%2Fdeposit&formceptionID=formception-40009759-98e8-e7ab-acd4-af084cc3916c&__opv=v1" allowTransparency="true" allow="geolocation; microphone; camera" allowFullScreen="true" name={201405066935048} id={201405066935048} style={{width: '10px', minWidth: '100%', display: 'block', overflow: 'hidden', border: 'none', height: '1500px'}} scrolling="yes" />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Deposit
